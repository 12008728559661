/* Layout */
export const DefaultWrapper = () => import('$layouts/DefaultWrapper.vue');

/* Layout */
export const LayoutPublic = () => import('$layouts/Public.vue');
export const LayoutPrivate = () => import('$layouts/Private.vue');

/* Login */
export const Login = () => import('$pages/auth/login/Login.vue').then();

/* Logout */
export const Logout = () => import('$pages/auth/login/Logout.vue').then();

/* ForgotPassword */
export const ForgotPassword = () => import('$pages/auth/forgot-password/ForgotPassword.vue');

/* Reset Password */
export const ResetPassword = () => import('$pages/auth/reset-password/ResetPassword.vue');

/* Register */
export const Register = () => import('$pages/auth/register/Register.vue');

/* Erros */
export const NotFound = () => import('$pages/errors/404.vue');


/* 2FA */
export const TwoFALogin = () => import('$pages/auth/2FA/TwoFALogin.vue').then();
export const Enable2FA = () => import('$pages/auth/2FA/Enable2FA.vue');
export const Disable2FA = () => import('$pages/auth/2FA/Disable2FA.vue');

/* Configuration */
export const ConfigurationList = () => import('$pages/configuration/List.vue');

export const TargetPublicList = () => import('$pages/configuration/target_public/List.vue');

export const ReasonsVaccineDelayList = () => import('$pages/configuration/reasons_vaccine_delay/List.vue');
export const ReasonsVaccineDelayNew = () => import('$pages/configuration/reasons_vaccine_delay/New.vue');
export const ReasonsVaccineDelayEdit = () => import('$pages/configuration/reasons_vaccine_delay/Edit.vue');

export const VaccinesList = () => import('$pages/configuration/vaccines/List.vue');
export const VaccinesNew = () => import('$pages/configuration/vaccines/New.vue');
export const VaccinesEdit = () => import('$pages/configuration/vaccines/Edit.vue');

export const DeadlinesList = () => import('$pages/configuration/deadlines/List.vue');
export const DeadlinesNew = () => import('$pages/configuration/deadlines/New.vue');
export const DeadlinesEdit = () => import('$pages/configuration/deadlines/Edit.vue');

export const GovernmentOfficeList = () => import('$pages/configuration/government_office/List.vue');
export const GovernmentOfficeNew = () => import('$pages/configuration/government_office/New.vue');
export const GovernmentOfficeEdit = () => import('$pages/configuration/government_office/Edit.vue');

export const GroupsList = () => import('$pages/configuration/groups/List.vue');

export const MenusList = () => import('$pages/configuration/menus/List.vue');
export const MenusRoleList = () => import('$pages/configuration/menus_role/List.vue');

/* Users */
export const UsersList = () => import('$pages/users/List.vue');
export const UsersNew = () => import('$pages/users/New.vue');
export const UsersEdit = () => import('$pages/users/Edit.vue');
export const ConfirmExternalUser = () => import('$pages/users/ConfirmExternalUser.vue');

/* Profile */
export const Profile = () => import('$pages/users/Profile.vue');

/* Home */
export const Home = () => import('$pages/home/Home.vue');

/* Home */
export const Painel = () => import('$pages/home/Painel.vue');

/* Dashboard */
export const Dashboard = () => import('$pages/dashboard/Dashboard.vue');

/* Events */
export const Events = () => import('$pages/events/Events.vue');
export const Visits = () => import('$pages/events/Visits.vue');
export const AlertAgentAlert = () => import('$pages/events/alert-agent/Alert.vue');
export const AlertSupervisorAlert = () => import('$pages/events/alert-supervisor/Alert.vue');
export const TechnicalAnalysis = () => import('$pages/events/technical-analysis/TechnicalAnalysis.vue');
// export const Forwarding = () => import('$pages/events/Forwarding.vue');
// export const VaccineRoom = () => import('$pages/events/VaccineRoom.vue');
export const CompletedVaccination = () => import('$pages/events/CompletedVaccination.vue');
// export const Close = () => import('$pages/events/Close.vue');

/* Reports */
export const Reports = () => import('$pages/reports/Reports.vue');

/* Tools */
export const Tools = () => import('$pages/tools/Tools.vue');

export const Records = () => import('$pages/records/Records.vue');

export const RecordsPending = () => import('$pages/records/RecordsPending.vue');
export const Step = () => import('$pages/records/Step.vue');

/* Records */
export const FormRecordStep1 = () => import('$pages/records/Step1.vue');
export const FormRecordStep2 = () => import('$pages/records/Step2.vue');
export const RecordsView = () => import('$pages/records/View.vue');


/* Alerts */
// export const Alerts = () => import('$pages/alerts/Alerts.vue');
// export const AlertsStep1 = () => import('$pages/alerts/Step1.vue');
// export const AlertsStep2 = () => import('$pages/records/alerts/Step2.vue');
// export const AlertsCreate = () => import('$pages/alerts/Create.vue');
export const AlertsEdit = () => import('$pages/records/Edit.vue')

/* Pending Alerts */
export const PendingAlerts = () => import('$pages/records/alerts/pendings/Pendings.vue');

/* Accession */
export const Accession = () => import('$pages/accession/Accessions.vue');
export const AccessionManage = () => import('$pages/accession/Manage.vue');
export const AccessionRegister = () => import('$pages/accession/Register.vue');
export const AccessionConfirmExternalUser = () => import('$pages/accession/ConfirmExternalUser.vue');
export const AccessionConfirmExternalUserPassword = () => import('$pages/accession/ConfirmExternalUserPassword.vue');

/* Events/Casos */
export const EventsOnGoing = () => import('$pages/events/OnGoing.vue');

